// @flow
import mergeAll from 'lodash/fp/mergeAll';
import { createUiOTheme, fontSize, lineHeight } from 'react-usit-ui';

import getSettings from '../../backend/settings';
import * as colors from '../colors';
import * as focus from '../focusFrame';

export type ThemeType =
  | 'DEFAULT'
  | 'FHI'
  | 'OSLOMET'
  | 'UIT'
  | 'UIB'
  | 'ISF'
  | 'USN'
  | 'NLA'
  | 'INN'
  | 'OUS'
  | 'HIMOLDE'
  | 'HIOF'
  | 'AHO'
  | 'NUBU'
  | 'NKVTS'
  | 'HLSENTERET'
  | 'RBUP'
  | 'NMBU'
  | 'NORD'
  | 'NTNU'
  | 'NORDLANDSFORSKNING'
  | 'HIVOLDA'
  | 'UNIS'
  | 'ANSGAR'
  | 'NORCE'
  | 'NOKUT'
  | 'VID'
  | 'UIA'
  | 'KHIO'
  | 'BI'
  | 'SYKEHUSET_INNLANDET'
  | 'HK'
  | 'NTNU_SAMFORSK'
  | 'VESTRE_VIKEN'
  | 'LANDBRUKSDIREKTORATET'
  | 'MF'
  | 'UIS'
  | 'EDUCSC'
  | 'PHS'
  | 'KRUS'
  | 'EDUCLOUD'
  | 'RADICH'
  | 'KIRKENSBYMISJON'
  | 'SSHF'
  | 'BARNEVERN_NOTODDEN'
  | 'LDH'
  | 'OSLONH'
  | 'NMH'
  | 'SIKT'
  | 'NORDKAPP_KOMMUNE'
  | 'HFY'
  | 'BGO'
  | 'HELSE_STAVANGER'
  | 'KULTURAKADEMIET'
  | 'BARRATTDUE'
  | 'SAMAS'
  | 'VETINST'
  | 'NIH'
  | 'BBJ'
  | 'DMMH'
  | 'HELSEDIREKTORATET'
  | 'MOBA'
  | 'GATEJURISTENTROMSO'
  | 'NOLOGO';

const defaults = {
  palette: {
    button: {
      primary: {
        borderRadius: '4px',
        background: colors.primary.dark,
        '&:hover, &:focus': {
          color: colors.ns.black,
          background: colors.primary.hover,
          borderColor: colors.primary.hover,
        },
        '&:focus': focus.focusFrameButton,
      },
      secondary: {
        background: '#fff',
        color: '#555555',

        border: '3px solid',
        borderRadius: '4px',
        '&:hover, &:focus': {
          background: colors.primary.border,
          color: '#222222',
        },
        '&:focus': focus.focusFrameButton,
      },
      text: {
        background: '#fff',
        color: colors.primary.dark,
        '&:hover, &:focus': {
          color: colors.primary.dark,
        },
        ...focus.underlineButtonLink,
      },
      border: {
        borderColor: colors.primary.dark,
      },
    },
  },
  typography: {
    h1: fontSize(36)(lineHeight(45)({})),
    h2: fontSize(26)(lineHeight(34)({})),
    h3: fontSize(22)(lineHeight(30)({})),
  },
  props: { publicPath: process.env.PUBLIC_PATH },
};

const filenameOnlyOf = requirePath => {
  const findAfterDoubleSlash = requirePath.match(/\/(\w+\.\w{3})/);
  if (findAfterDoubleSlash) {
    return findAfterDoubleSlash[1];
  } else if (process.env.NODE_ENV === 'test') {
    return requirePath;
  } else {
    throw new Error('Unable to remove dist path from resource url');
  }
};

const blackFooterWithLogo = logoBackground => ({
  palette: { footer: { background: colors.primary.dark, text: '#fff' } },
  graphics: {
    footer: {
      logoBackground,
    },
  },
});

const getLocalizedLogo = (theme: string, locale: string) => {
  const logoLocale = locale === 'en' ? locale : 'no';
  return logos[theme][logoLocale];
};

const logos = {
  DEFAULT: {
    no: filenameOnlyOf(require('./default/images/logo-no.svg')),
    en: filenameOnlyOf(require('./default/images/logo-en.svg')),
  },
  FHI: {
    no: filenameOnlyOf(require('./fhi/images/logo-no.svg')),
    en: filenameOnlyOf(require('./fhi/images/logo-en.svg')),
  },
  OSLOMET: {
    no: filenameOnlyOf(require('./oslomet/images/logo-no.png')),
    en: filenameOnlyOf(require('./oslomet/images/logo-en.png')),
  },
  USN: {
    no: filenameOnlyOf(require('./usn/images/logo-no.svg')),
    en: filenameOnlyOf(require('./usn/images/logo-en.svg')),
  },
  ISF: {
    no: filenameOnlyOf(require('./isf/images/logo-no.png')),
    en: filenameOnlyOf(require('./isf/images/logo-en.png')),
  },
  UIT: {
    no: filenameOnlyOf(require('./uit/images/logo-no.png')),
    en: filenameOnlyOf(require('./uit/images/logo-en.png')),
  },
  NLA: {
    no: filenameOnlyOf(require('./nla/images/logo-no.png')),
    en: filenameOnlyOf(require('./nla/images/logo-en.png')),
  },
  NOLOGO: {
    no: filenameOnlyOf(require('./nologo/images/logo-no.svg')),
    en: filenameOnlyOf(require('./nologo/images/logo-en.svg')),
  },
  UIB: {
    no: filenameOnlyOf(require('./uib/images/logo-no.png')),
    en: filenameOnlyOf(require('./uib/images/logo-en.png')),
  },
  INN: {
    no: filenameOnlyOf(require('./inn/images/logo-no.png')),
    en: filenameOnlyOf(require('./inn/images/logo-en.png')),
  },
  HIMOLDE: {
    no: filenameOnlyOf(require('./himolde/images/logo-no.svg')),
    en: filenameOnlyOf(require('./himolde/images/logo-en.svg')),
  },
  HIOF: {
    no: filenameOnlyOf(require('./hiof/images/logo-no.svg')),
    en: filenameOnlyOf(require('./hiof/images/logo-en.svg')),
  },
  AHO: {
    no: filenameOnlyOf(require('./aho/images/logo-no.svg')),
    en: filenameOnlyOf(require('./aho/images/logo-en.svg')),
  },
  OUS: {
    no: filenameOnlyOf(require('./ous/images/logo-no.png')),
    en: filenameOnlyOf(require('./ous/images/logo-en.png')),
  },
  NKVTS: {
    no: filenameOnlyOf(require('./nkvts/images/logo-no.svg')),
    en: filenameOnlyOf(require('./nkvts/images/logo-en.svg')),
  },
  NUBU: {
    no: filenameOnlyOf(require('./nubu/images/logo-no.svg')),
    en: filenameOnlyOf(require('./nubu/images/logo-en.svg')),
  },
  HLSENTERET: {
    no: filenameOnlyOf(require('./hlsenteret/images/logo-no.png')),
    en: filenameOnlyOf(require('./hlsenteret/images/logo-en.png')),
  },
  RBUP: {
    no: filenameOnlyOf(require('./rbup/images/logo-no.svg')),
    en: filenameOnlyOf(require('./rbup/images/logo-en.svg')),
  },
  NMBU: {
    no: filenameOnlyOf(require('./nmbu/images/logo-no.svg')),
    en: filenameOnlyOf(require('./nmbu/images/logo-en.svg')),
  },
  NORD: {
    no: filenameOnlyOf(require('./nord/images/logo-no.png')),
    en: filenameOnlyOf(require('./nord/images/logo-en.png')),
  },
  NTNU: {
    no: filenameOnlyOf(require('./ntnu/images/logo-no.png')),
    en: filenameOnlyOf(require('./ntnu/images/logo-en.png')),
  },
  NORDLANDSFORSKNING: {
    no: filenameOnlyOf(require('./nordlandsforskning/images/logo-no.svg')),
    en: filenameOnlyOf(require('./nordlandsforskning/images/logo-en.svg')),
  },
  HIVOLDA: {
    no: filenameOnlyOf(require('./hivolda/images/logo-no.svg')),
    en: filenameOnlyOf(require('./hivolda/images/logo-en.svg')),
  },
  UNIS: {
    no: filenameOnlyOf(require('./unis/images/logo-no.png')),
    en: filenameOnlyOf(require('./unis/images/logo-en.png')),
  },
  ANSGAR: {
    no: filenameOnlyOf(require('./ansgar/images/logo-no.svg')),
    en: filenameOnlyOf(require('./ansgar/images/logo-en.svg')),
  },
  NORCE: {
    no: filenameOnlyOf(require('./norce/images/logo-no.svg')),
    en: filenameOnlyOf(require('./norce/images/logo-en.svg')),
  },
  NOKUT: {
    no: filenameOnlyOf(require('./nokut/images/logo-no.png')),
    en: filenameOnlyOf(require('./nokut/images/logo-en.png')),
  },
  VID: {
    no: filenameOnlyOf(require('./vid/images/logo-no.png')),
    en: filenameOnlyOf(require('./vid/images/logo-en.png')),
  },
  UIA: {
    no: filenameOnlyOf(require('./uia/images/logo-no.png')),
    en: filenameOnlyOf(require('./uia/images/logo-en.png')),
  },
  KHIO: {
    no: filenameOnlyOf(require('./khio/images/logo-no.png')),
    en: filenameOnlyOf(require('./khio/images/logo-en.png')),
  },
  BI: {
    no: filenameOnlyOf(require('./bi/images/logo-no.svg')),
    en: filenameOnlyOf(require('./bi/images/logo-en.svg')),
  },
  SYKEHUSET_INNLANDET: {
    no: filenameOnlyOf(require('./sykehuset_innlandet/images/logo-no.svg')),
    en: filenameOnlyOf(require('./sykehuset_innlandet/images/logo-en.svg')),
  },
  HK: {
    no: filenameOnlyOf(require('./hk/images/logo-no.svg')),
    en: filenameOnlyOf(require('./hk/images/logo-en.svg')),
  },
  NTNU_SAMFORSK: {
    no: filenameOnlyOf(require('./ntnu_samforsk/images/logo-no.svg')),
    en: filenameOnlyOf(require('./ntnu_samforsk/images/logo-en.svg')),
  },
  VESTRE_VIKEN: {
    no: filenameOnlyOf(require('./vestre_viken/images/logo-no.png')),
    en: filenameOnlyOf(require('./vestre_viken/images/logo-en.png')),
  },
  LANDBRUKSDIREKTORATET: {
    no: filenameOnlyOf(require('./landbruksdirektoratet/images/logo-no.svg')),
    en: filenameOnlyOf(require('./landbruksdirektoratet/images/logo-en.svg')),
  },
  MF: {
    no: filenameOnlyOf(require('./mf/images/logo-no.svg')),
    en: filenameOnlyOf(require('./mf/images/logo-en.svg')),
  },
  UIS: {
    no: filenameOnlyOf(require('./uis/images/logo-no.svg')),
    en: filenameOnlyOf(require('./uis/images/logo-en.svg')),
  },
  EDUCSC: {
    no: filenameOnlyOf(require('./educsc/images/logo-no.svg')),
    en: filenameOnlyOf(require('./educsc/images/logo-en.svg')),
  },
  PHS: {
    no: filenameOnlyOf(require('./phs/images/logo-no.png')),
    en: filenameOnlyOf(require('./phs/images/logo-en.png')),
  },
  KRUS: {
    no: filenameOnlyOf(require('./krus/images/logo-no.png')),
    en: filenameOnlyOf(require('./krus/images/logo-en.png')),
  },
  EDUCLOUD: {
    no: filenameOnlyOf(require('./educloud/images/logo-no.svg')),
    en: filenameOnlyOf(require('./educloud/images/logo-en.svg')),
  },
  RADICH: {
    no: filenameOnlyOf(require('./radich/images/logo-no.png')),
    en: filenameOnlyOf(require('./radich/images/logo-en.png')),
  },
  KIRKENSBYMISJON: {
    no: filenameOnlyOf(require('./kirkensbymisjon/images/logo-no.svg')),
    en: filenameOnlyOf(require('./kirkensbymisjon/images/logo-en.svg')),
  },
  SSHF: {
    no: filenameOnlyOf(require('./sshf/images/logo-no.png')),
    en: filenameOnlyOf(require('./sshf/images/logo-en.png')),
  },
  BARNEVERN_NOTODDEN: {
    no: filenameOnlyOf(require('./barnevern_notodden/images/logo-no.svg')),
    en: filenameOnlyOf(require('./barnevern_notodden/images/logo-en.svg')),
  },
  LDH: {
    no: filenameOnlyOf(require('./ldh/images/logo-no.svg')),
    en: filenameOnlyOf(require('./ldh/images/logo-en.svg')),
  },
  OSLONH: {
    no: filenameOnlyOf(require('./oslonh/images/logo-no.png')),
    en: filenameOnlyOf(require('./oslonh/images/logo-en.png')),
  },
  NMH: {
    no: filenameOnlyOf(require('./nmh/images/logo-no.svg')),
    en: filenameOnlyOf(require('./nmh/images/logo-en.svg')),
  },
  SIKT: {
    no: filenameOnlyOf(require('./sikt/images/logo-no.svg')),
    en: filenameOnlyOf(require('./sikt/images/logo-en.svg')),
  },
  NORDKAPP_KOMMUNE: {
    no: filenameOnlyOf(require('./nordkapp_kommune/images/logo-no.svg')),
    en: filenameOnlyOf(require('./nordkapp_kommune/images/logo-en.svg')),
  },
  HFY: {
    no: filenameOnlyOf(require('./hfy/images/logo-no.svg')),
    en: filenameOnlyOf(require('./hfy/images/logo-en.svg')),
  },
  BGO: {
    no: filenameOnlyOf(require('./bgo/images/logo-no.svg')),
    en: filenameOnlyOf(require('./bgo/images/logo-en.svg')),
  },
  HELSE_STAVANGER: {
    no: filenameOnlyOf(require('./helse_stavanger/images/logo-no.png')),
    en: filenameOnlyOf(require('./helse_stavanger/images/logo-en.png')),
  },
  KULTURAKADEMIET: {
    no: filenameOnlyOf(require('./kulturakademiet/images/logo-no.svg')),
    en: filenameOnlyOf(require('./kulturakademiet/images/logo-en.svg')),
  },
  BARRATTDUE: {
    no: filenameOnlyOf(require('./barrattdue/images/logo-no.svg')),
    en: filenameOnlyOf(require('./barrattdue/images/logo-en.svg')),
  },
  SAMAS: {
    no: filenameOnlyOf(require('./samas/images/logo-no.svg')),
    en: filenameOnlyOf(require('./samas/images/logo-en.svg')),
  },
  VETINST: {
    no: filenameOnlyOf(require('./vetinst/images/logo-no.svg')),
    en: filenameOnlyOf(require('./vetinst/images/logo-en.svg')),
  },
  NIH: {
    no: filenameOnlyOf(require('./nih/images/logo-no.svg')),
    en: filenameOnlyOf(require('./nih/images/logo-en.svg')),
  },
  BBJ: {
    no: filenameOnlyOf(require('./bbj/images/logo-no.svg')),
    en: filenameOnlyOf(require('./bbj/images/logo-en.svg')),
  },
  DMMH: {
    no: filenameOnlyOf(require('./dmmh/images/logo-no.svg')),
    en: filenameOnlyOf(require('./dmmh/images/logo-en.svg')),
  },
  HELSEDIREKTORATET: {
    no: filenameOnlyOf(require('./helsedirektoratet/images/logo-no.svg')),
    en: filenameOnlyOf(require('./helsedirektoratet/images/logo-en.svg')),
  },
  MOBA: {
    no: filenameOnlyOf(require('./moba/images/logo-no.svg')),
    en: filenameOnlyOf(require('./moba/images/logo-en.svg')),
  },
  GATEJURISTENTROMSO: {
    no: filenameOnlyOf(require('./gatejuristentromso/images/logo-no.svg')),
    en: filenameOnlyOf(require('./gatejuristentromso/images/logo-en.svg')),
  },
};

const themes = locale => ({
  DEFAULT: blackFooterWithLogo({
    url: getLocalizedLogo('DEFAULT', locale),
    position: ['80px', '55px'],
    size: ['255px', 'auto'],
    // //  mobSize: ['150px', 'auto'],
    // //  containerWidth: '250px',
    //  imageHeight: '80px',
  }),
  FHI: blackFooterWithLogo({
    url: getLocalizedLogo('FHI', locale),
    position: ['100px', '30px'],
    size: ['auto', '80px'],
  }),
  OSLOMET: blackFooterWithLogo({
    url: getLocalizedLogo('OSLOMET', locale),
    position: ['120px', '40px'],
    size: ['150px', 'auto'],
    // //  mobSize: ['150px', 'auto'],
    //  containerWidth: '300px',
    //  imageHeight: '80px',
  }),
  USN: blackFooterWithLogo({
    url: getLocalizedLogo('USN', locale),
    position: ['95px', '45px'],
    size: ['230px', 'auto'],
    // //  mobSize: ['150px', 'auto'],
    //  containerWidth: '350px',
    //  imageHeight: '80px',
  }),
  ISF: blackFooterWithLogo({
    url: getLocalizedLogo('ISF', locale),
    position: ['95px', '53px'],
    size: ['200px', 'auto'],
    // //  mobSize: ['150px', 'auto'],
    //  containerWidth: '240px',
    //  imageHeight: '80px',
  }),
  UIT: blackFooterWithLogo({
    url: getLocalizedLogo('UIT', locale),
    position: ['137px', '22px'],
    size: ['120px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '300px',
    //  imageHeight: '130px',
  }),
  NLA: blackFooterWithLogo({
    url: getLocalizedLogo('NLA', locale),
    position: ['82px', '35px'],
    size: ['190px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '290px',
    //  imageHeight: '80px',
  }),
  NOLOGO: blackFooterWithLogo({
    url: getLocalizedLogo('NOLOGO', locale),
    position: ['15px', '22px'],
    size: ['150px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '300px',
    //  imageHeight: '0px',
  }),
  UIB: blackFooterWithLogo({
    url: getLocalizedLogo('UIB', locale),
    position: ['80px', '58px'],
    size: ['250px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '430px',
    //  imageHeight: '80px',
  }),
  INN: blackFooterWithLogo({
    url: getLocalizedLogo('INN', locale),
    position: ['79px', '56px'],
    size: ['270px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '370px',
    //  imageHeight: '80px',
  }),
  HIMOLDE: blackFooterWithLogo({
    url: getLocalizedLogo('HIMOLDE', locale),
    position: ['78px', '50px'],
    size: ['270px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '330px',
    //  imageHeight: '80px',
  }),
  HIOF: blackFooterWithLogo({
    url: getLocalizedLogo('HIOF', locale),
    position: ['82px', '46px'],
    size: ['270px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '355px',
    //  imageHeight: '80px',
  }),
  AHO: blackFooterWithLogo({
    url: getLocalizedLogo('AHO', locale),
    position: ['80px', '71px'],
    size: ['260px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '455px',
    //  imageHeight: '80px',
  }),
  OUS: blackFooterWithLogo({
    url: getLocalizedLogo('OUS', locale),
    position: ['86px', '56px'],
    size: ['270px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '375px',
    //  imageHeight: '80px',
  }),
  NKVTS: blackFooterWithLogo({
    url: getLocalizedLogo('NKVTS', locale),
    position: ['12px', '23px'],
    size: ['150px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '300px',
    //  imageHeight: '0px',
  }),
  NUBU: blackFooterWithLogo({
    url: getLocalizedLogo('NUBU', locale),
    position: ['80px', '60px'],
    size: ['255px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '440px',
    //  imageHeight: '80px',
  }),
  HLSENTERET: blackFooterWithLogo({
    url: getLocalizedLogo('HLSENTERET', locale),
    position: ['80px', '45px'],
    size: ['250px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '420px',
    //  imageHeight: '80px',
  }),
  RBUP: blackFooterWithLogo({
    url: getLocalizedLogo('RBUP', locale),
    position: ['80px', '70px'],
    size: ['255px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '450px',
    //  imageHeight: '80px',
  }),
  NMBU: blackFooterWithLogo({
    url: getLocalizedLogo('NMBU', locale),
    position: ['80px', '40px'],
    size: ['210px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '380px',
    //  imageHeight: '80px',
  }),
  NORD: blackFooterWithLogo({
    url: getLocalizedLogo('NORD', locale),
    position: ['80px', '40px'],
    size: ['200px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '320px',
    //  imageHeight: '80px',
  }),
  NTNU: blackFooterWithLogo({
    url: getLocalizedLogo('NTNU', locale),
    position: ['85px', '65px'],
    size: ['190px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '250px',
    //  imageHeight: '80px',
  }),
  NORDLANDSFORSKNING: blackFooterWithLogo({
    url: getLocalizedLogo('NORDLANDSFORSKNING', locale),
    position: ['110px', '30px'],
    size: ['150px', 'auto'],
    // //  mobSize: ['150px', 'auto'],
    //  containerWidth: '220px',
    //  imageHeight: '110px',
  }),
  HIVOLDA: blackFooterWithLogo({
    url: getLocalizedLogo('HIVOLDA', locale),
    position: ['80px', '63px'],
    size: ['250px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '350px',
    //  imageHeight: '45px',
  }),
  UNIS: blackFooterWithLogo({
    url: getLocalizedLogo('UNIS', locale),
    position: ['130px', '35px'],
    size: ['110px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '140px',
    //  imageHeight: '100px',
  }),
  ANSGAR: blackFooterWithLogo({
    url: getLocalizedLogo('ANSGAR', locale),
    position: ['80px', '24px'],
    size: ['190px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '200px',
    //  imageHeight: '80px',
  }),
  NORCE: blackFooterWithLogo({
    url: getLocalizedLogo('NORCE', locale),
    position: ['80px', '52px'],
    size: ['170px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '240px',
    //  imageHeight: '60px',
  }),
  NOKUT: blackFooterWithLogo({
    url: getLocalizedLogo('NOKUT', locale),
    position: ['80px', '42px'],
    size: ['170px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '300px',
    //  imageHeight: '80px',
  }),
  VID: blackFooterWithLogo({
    url: getLocalizedLogo('VID', locale),
    position: ['80px', '42px'],
    size: ['150px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '250px',
    //  imageHeight: '80px',
  }),
  UIA: blackFooterWithLogo({
    url: getLocalizedLogo('UIA', locale),
    position: ['88px', '45px'],
    size: ['150px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '230px',
    //  imageHeight: '80px',
  }),
  KHIO: blackFooterWithLogo({
    url: getLocalizedLogo('KHIO', locale),
    position: ['80px', '60px'],
    size: ['260px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '380px',
    //  imageHeight: '70px',
  }),
  BI: blackFooterWithLogo({
    url: getLocalizedLogo('BI', locale),
    position: ['120px', '38px'],
    size: ['140px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '240px',
    //  imageHeight: '110px',
  }),
  SYKEHUSET_INNLANDET: blackFooterWithLogo({
    url: getLocalizedLogo('SYKEHUSET_INNLANDET', locale),
    position: ['80px', '54px'],
    size: ['260px', 'auto'],
    mobSize: ['330px', 'auto'],
    //  containerWidth: '420px',
    //  imageHeight: '80px',
  }),
  HK: blackFooterWithLogo({
    url: getLocalizedLogo('HK', locale),
    position: ['85px', '47px'],
    size: ['170px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '200px',
    //  imageHeight: '80px',
  }),
  NTNU_SAMFORSK: blackFooterWithLogo({
    url: getLocalizedLogo('NTNU_SAMFORSK', locale),
    position: ['80px', '58px'],
    size: ['190px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '200px',
    //  imageHeight: '70px',
  }),
  VESTRE_VIKEN: blackFooterWithLogo({
    url: getLocalizedLogo('VESTRE_VIKEN', locale),
    position: ['80px', '63px'],
    size: ['200px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '200px',
    //  imageHeight: '50px',
  }),
  LANDBRUKSDIREKTORATET: blackFooterWithLogo({
    url: getLocalizedLogo('LANDBRUKSDIREKTORATET', locale),
    position: ['80px', '55px'],
    size: ['240px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '325px',
    //  imageHeight: '75px',
  }),
  MF: blackFooterWithLogo({
    url: getLocalizedLogo('MF', locale),
    position: ['80px', '50px'],
    size: ['250px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '340px',
    //  imageHeight: '75px',
  }),
  UIS: blackFooterWithLogo({
    url: getLocalizedLogo('UIS', locale),
    position: ['155px', '35px'],
    size: ['80px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '120px',
    //  imageHeight: '110px',
  }),
  EDUCSC: blackFooterWithLogo({
    url: getLocalizedLogo('EDUCSC', locale),
    position: ['80px', '53px'],
    size: ['240px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '360px',
    //  imageHeight: '80px',
  }),
  PHS: blackFooterWithLogo({
    url: getLocalizedLogo('PHS', locale),
    position: ['80px', '68px'],
    size: ['200px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '320px',
    //  imageHeight: '50px',
  }),
  KRUS: blackFooterWithLogo({
    url: getLocalizedLogo('KRUS', locale),
    position: ['110px', '30px'],
    size: ['150px', 'auto'],
    //  containerWidth: '200px',
    //  imageHeight: '130px',
  }),
  EDUCLOUD: blackFooterWithLogo({
    url: getLocalizedLogo('EDUCLOUD', locale),
    position: ['80px', '50px'],
    size: ['180px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '300px',
    //  imageHeight: '70px',
  }),
  RADICH: blackFooterWithLogo({
    url: getLocalizedLogo('RADICH', locale),
    position: ['80px', '60px'],
    size: ['220px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '350px',
    //  imageHeight: '70px',
  }),
  KIRKENSBYMISJON: blackFooterWithLogo({
    url: getLocalizedLogo('KIRKENSBYMISJON', locale),
    position: ['107px', '30px'],
    size: ['70px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '240px',
    //  imageHeight: '100px',
  }),
  SSHF: blackFooterWithLogo({
    url: getLocalizedLogo('SSHF', locale),
    position: ['80px', '71px'],
    size: ['240px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '370px',
    //  imageHeight: '60px',
  }),
  BARNEVERN_NOTODDEN: blackFooterWithLogo({
    url: getLocalizedLogo('BARNEVERN_NOTODDEN', locale),
    position: ['80px', '65px'],
    size: ['210px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '400px',
    //  imageHeight: '70px',
  }),
  LDH: blackFooterWithLogo({
    url: getLocalizedLogo('LDH', locale),
    position: ['80px', '53px'],
    size: ['230px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '350px',
    //  imageHeight: '80px',
  }),
  OSLONH: blackFooterWithLogo({
    url: getLocalizedLogo('OSLONH', locale),
    position: ['80px', '62px'],
    size: ['220px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '270px',
    //  imageHeight: '60px',
  }),
  NMH: blackFooterWithLogo({
    url: getLocalizedLogo('NMH', locale),
    position: ['80px', '45px'],
    size: ['230px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '330px',
    //  imageHeight: '80px',
  }),
  SIKT: blackFooterWithLogo({
    url: getLocalizedLogo('SIKT', locale),
    position: ['90px', '65px'],
    size: ['150px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '250px',
    //  imageHeight: '60px',
  }),
  NORDKAPP_KOMMUNE: blackFooterWithLogo({
    url: getLocalizedLogo('NORDKAPP_KOMMUNE', locale),
    position: ['160px', '30px'],
    size: ['70px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '110px',
    //  imageHeight: '80px',
  }),
  HFY: blackFooterWithLogo({
    url: getLocalizedLogo('HFY', locale),
    position: ['114px', '50px'],
    size: ['160px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '200px',
    //  imageHeight: '70px',
  }),
  BGO: blackFooterWithLogo({
    url: getLocalizedLogo('BGO', locale),
    position: ['92px', '38px'],
    size: ['150px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '220px',
    //  imageHeight: '80px',
  }),
  HELSE_STAVANGER: blackFooterWithLogo({
    url: getLocalizedLogo('HELSE_STAVANGER', locale),
    position: ['92px', '38px'],
    size: ['275px', 'auto'],
    //  mobSize: ['150px', 'auto'],
    //  containerWidth: '200px',
    //  imageHeight: '80px',
  }),
  KULTURAKADEMIET: blackFooterWithLogo({
    url: getLocalizedLogo('KULTURAKADEMIET', locale),
    position: ['92px', '38px'],
    size: ['250px', 'auto'],
  }),
  BARRATTDUE: blackFooterWithLogo({
    url: getLocalizedLogo('BARRATTDUE', locale),
    position: ['92px', '38px'],
    size: ['250px', 'auto'],
  }),
  SAMAS: blackFooterWithLogo({
    url: getLocalizedLogo('SAMAS', locale),
    position: ['92px', '38px'],
    size: ['210px', 'auto'],
  }),
  VETINST: blackFooterWithLogo({
    url: getLocalizedLogo('VETINST', locale),
    position: ['92px', '38px'],
    size: ['250px', 'auto'],
  }),
  NIH: blackFooterWithLogo({
    url: getLocalizedLogo('NIH', locale),
    position: ['92px', '38px'],
    size: ['320px', 'auto'],
  }),
  BBJ: blackFooterWithLogo({
    url: getLocalizedLogo('BBJ', locale),
    position: ['92px', '38px'],
    size: ['150px', 'auto'],
  }),
  DMMH: blackFooterWithLogo({
    url: getLocalizedLogo('DMMH', locale),
    position: ['92px', '38px'],
    size: ['95px', 'auto'],
  }),
  HELSEDIREKTORATET: blackFooterWithLogo({
    url: getLocalizedLogo('HELSEDIREKTORATET', locale),
    position: ['92px', '38px'],
    size: ['270px', 'auto'],
  }),
  MOBA: blackFooterWithLogo({
    url: getLocalizedLogo('MOBA', locale),
    position: ['100px', '30px'],
    size: ['auto', '80px'],
  }),
  GATEJURISTENTROMSO: blackFooterWithLogo({
    url: getLocalizedLogo('GATEJURISTENTROMSO', locale),
    position: ['100px', '30px'],
    size: ['auto', '80px'],
  }),
});

const getTheme = (name: ThemeType) =>
  createUiOTheme({
    ...mergeAll([defaults, themes(getSettings().languageCode)[name]]),
  });

export const getCurrentFormThemeName = () => getSettings().formTheme;

export const getThemeForCurrentForm = () => getTheme(getCurrentFormThemeName());

export default getTheme;
